<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex align-center mb-2">
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="shadow-off mr-2" small icon @click="$nova.gotoLink({path: '/tds/monthly', hash: year})"><v-icon>mdi-arrow-left</v-icon></v-btn>
              </template>
              <span>Back to Analysis List</span>
            </v-tooltip>
            <div>
              <v-chip small label v-if="(stage === 0)" color="info">In progress</v-chip>
              <v-chip small label v-else-if="(stage === 1)" color="warning">Under review</v-chip>
              <v-chip small label v-else-if="(stage === 2)" color="success">Completed</v-chip>
              <v-chip small label v-else-if="(stage === 3)" color="grey">Not due</v-chip>
              <v-chip small label v-else class="">Upcoming</v-chip>
            </div>
            <div class="ml-2">
              <v-chip  small label color="warning" v-if="isreviewer">Reviewer</v-chip>
              <v-chip  small label color="info" v-if="ispreparer">Preparer </v-chip>
              <v-chip  small label v-if="(!isreviewer && !ispreparer)">Viewer</v-chip> 
            </div>
            <v-spacer></v-spacer>
            <v-btn class="shadow-off" small text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
          </div>
          <div class="d-flex align-center" v-if="(stage > -1)">
            <div>
              <div class="d-flex align-center">
                <div class="subtitle-1 font-weight-bold mr-2">{{metadata.description}}</div>
              </div>
              <div class="subtitle-1">
                <v-icon class="mr-2">mdi-calendar-range</v-icon>
                <span class="mr-2">{{new Date(new Date().setMonth((month || 1)-1)).toLocaleString('default', { month: 'long' })}}</span>
                <span class="mr-2">{{metadata.year}}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div v-if="(Object.keys(rundetails).length > 0 && !running && !runerror)">
            
              <v-btn color="primary" class="shadow-off" v-if="(stage===0 && !isreviewer) && !(!isreviewer && !ispreparer)" small @click="startAnalysis()">
                <v-icon class="mr-1">mdi-play</v-icon>
                Re-run Analysis
              </v-btn>
              <v-btn color="warning" class="ml-2 shadow-off" v-if="(stage===0 && !isreviewer) && !(!isreviewer && !ispreparer)" small @click="sendForReview()">
                <v-icon class="mr-1">mdi-check</v-icon>
                Send for Review
              </v-btn>
              <v-btn color="error" class="ml-2 shadow-off" v-if="stage===1 && isreviewer" small @click="openProject('back')">
                <v-icon class="mr-1">mdi-arrow-left</v-icon>
                Send back to preparer
              </v-btn>
              <v-btn color="success" class="ml-2 shadow-off" v-if="stage===1 && isreviewer" small @click="closeProject()">
                <v-icon class="mr-1">mdi-lock</v-icon>
                Review & Close
              </v-btn>
              <v-btn color="info" class="ml-2 shadow-off" v-if="stage===3 && isreviewer" small @click="openProject()">
                <v-icon class="mr-1">mdi-lock-open-variant</v-icon>
                Re-open Analysis
              </v-btn>
            </div>
            <div v-if="(isreviewer && stage === 0)" class="warning--text">
              Waiting for preparer to finish the analysis
            </div>

          </div>
        </v-card-text>
        <v-card-text class="pt-0" v-if="(stage > -1)">
          <v-alert v-if="running" text border="left" class="py-2" color="info" :value="true">
            <div class="runningloader ml-2 mr-4">
              <span class="inner-circle"></span>
              <span class="outer-circle"></span>
            </div>
            This analysis is currently running
          </v-alert>
          <v-alert v-else-if="(!Object.hasOwnProperty.call((metadata || {}), 'parameters') && needinputparameter)" text border="left" class="py-2" type="info" :value="true">
            Save Input Parameters to proceed with the file upload
          </v-alert>
          <v-alert v-else-if="!allfileuploaded" text border="left" class="py-2" type="info" :value="true">
            Upload all the files to start the analysis
            <v-btn color="primary" v-if="!isreviewer && tab!=1" class="ml-2 shadow-off" small @click="tab=1">
              <v-icon class="mr-1">mdi-cloud-upload-outline</v-icon>
              Proceed
            </v-btn>
          </v-alert>
          <v-alert v-else-if="runerror" text border="left" class="py-2" type="error" :value="true">
            There was an error while running the analysis. Please re-run after sometime.
            <v-btn color="primary" class="ml-2 shadow-off" small @click="startAnalysis()">
              <v-icon class="mr-1">mdi-play</v-icon>
              Run Analysis
            </v-btn>
          </v-alert>
          <v-alert v-else-if="(((Object.hasOwnProperty.call((metadata || {}), 'parameters')) || !needinputparameter) && allfileuploaded && Object.keys(rundetails).length === 0)" text border="left" class="py-2" type="info" :value="true">
            Input parameters & Files are saved. Do you want to run the analysis?
            <v-btn color="primary" class="ml-2 shadow-off" small @click="startAnalysis()">
              <v-icon class="mr-1">mdi-play</v-icon>
              Run Analysis
            </v-btn>
          </v-alert>
          <div class="">
            <div class="">
              <v-tabs v-model="tab" height="40">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab :key="0" class="tds-menu-tab text-transform-none justify-start"><v-icon class="mr-2">mdi-play-box-multiple-outline</v-icon>Analysis</v-tab>
                <v-tab v-if="needinputparameter" :key="1" class="tds-menu-tab text-transform-none justify-start"><v-icon class="mr-2">mdi-play-box-multiple-outline</v-icon>Input Parameters</v-tab>
                <v-tab v-if="(((metadata || {}).parameters || []).length > 0) || !needinputparameter" :key="2" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>Files</v-tab>
                <v-tab v-if="Object.keys(rundetails).length > 0" :key="3" @click="readAnnexure(formattedannexurelist[1].collection, formattedannexurelist[1].name, selectedresulttab=formattedannexurelist[1].name)" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-format-list-bulleted</v-icon>Result</v-tab>
              </v-tabs>
            </div>
            <v-divider></v-divider>
            <div class="flex-grow-1 d-block">
              <v-tabs-items v-model="tab" class="">
                <v-tab-item :key="0">
                  <div>
                    <div class="pa-2 hoverable">
                      <div class="subtitle-1 pb-2">1. Data Sanitization Check</div>
                      <div class="ml-4">
                        <div class="d-flex align-center mb-0" v-for="(v,k) in {'TDS_RECO4': 'Blank values in important columns', 'TDS_RECO5': 'Invalid PAN format'}" :key="k">
                          <v-icon class="mr-2">mdi-menu-right</v-icon>
                          {{v}}
                          <template>
                            <v-icon v-if="(rundetails[k] || {}).issue === 1" class="ml-2" color="error">mdi-exclamation-thick</v-icon>
                            <v-icon v-else-if="((rundetails[k] || {}).stage || 0) === 0" class="ml-2" color="info">mdi-timer-sand</v-icon>
                            <v-icon v-else-if="(rundetails[k] || {}).stage === 1" class="ml-2" color="success">mdi-check</v-icon>
                            <v-btn v-if="(rundetails[k] || {}).issue === 1" small elevation="0" icon class="ml-2" @click="sanitizedialog=true;sanitizedialogtext=v;resultcode=k;readAnnexure((((rundetails[resultcode] || {}).annexure || [])[0] || {}).collection, (item.name === 'Multiple Rates')?true:false)"><v-icon>mdi-format-list-bulleted</v-icon></v-btn>
                            <v-btn v-if="(rundetails[k] || {}).issue === 1" small elevation="0" icon class="ml-2" @click="downloadResult((rundetails[k] || {}).verification)"><v-icon>mdi-file-excel</v-icon></v-btn>
                          </template>
                        </div>
                      </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2 hoverable">
                      <div class="subtitle-1 pb-2">2. Monthly verifications</div>
                      <div class="ml-4">
                        <div class="d-flex align-center mb-2" v-for="(v,k) in {'TDS_RECO6': 'Computation of TDS amount as per the rates provided in the Vendor Master and comparison with Invoice Dump'}" :key="k">
                          <v-icon class="mr-2">mdi-menu-right</v-icon>
                          {{v}}
                          <template v-if="((rundetails[k] || {}).stage === 1)">
                            <v-btn small elevation="0" icon class="ml-2" @click="downloadResult((rundetails[k] || {}).verification)"><v-icon>mdi-file-excel</v-icon></v-btn>
                          </template>
                          <template v-else-if="((rundetails[k] || {}).stage === 2)">
                            <v-icon label color="error" class="ml-2">mdi-exclamation-thick</v-icon>
                          </template>
                          <template v-else>
                            <v-icon label color="info" class="ml-2">mdi-timer-sand</v-icon>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item :key="1" v-if="needinputparameter">
                  <div>
                    <div class="d-flex my-2">
                      <v-btn v-if="(!isreviewer && !running && stage===0) && !(!isreviewer && !ispreparer)" color="primary" small elevation="0" @click="saveParameters()" >Save Input Parameters</v-btn>
                      <v-spacer></v-spacer>
                    </div>
                    <div class="" v-for="(pv,pk) in (analysis.parameter || [])" :key="pk">
                      <div class="d-flex hoverable px-4 py-2">
                        <div>
                          <span class="font-weight-bold">{{pk+1}}.</span>
                          <span class="mx-2">{{pv.name || "Name not found!!"}}</span>
                        </div>
                        <v-spacer></v-spacer>
                        <div>
                          <div v-if="pv.type==='number'" class="parameter-input">
                            <lb-number v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer || stage === 2"/>
                          </div>
                          <div v-else-if="pv.type==='string'" class="parameter-input">
                            <lb-string v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)"/>
                          </div>
                          <div v-else-if="pv.type==='date'" class="parameter-input">
                            <lb-date v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)"/>
                          </div>
                          <div v-else-if="pv.type==='year'" class="parameter-input">
                            <lb-year v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)"/>
                          </div>
                          <div v-else-if="pv.type==='month'" class="parameter-input">
                            <lb-month v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)"/>
                          </div>
                          <div v-else-if="pv.type==='time'" class="parameter-input">
                            <lb-time v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)"/>
                          </div>
                          <div v-else-if="pv.type==='daterange'" class="parameter-input">
                            <lb-daterange v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)"/>
                          </div>
                          <div v-else-if="pv.type==='timerange'" class="parameter-input">
                            <lb-timerange v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)"/>
                          </div>
                          <div v-else-if="['numberarray','stringarray','datearray','yeararray','montharray','timearray','daterangearray','timerangearray'].indexOf(pv.type) > -1" class="parameter-input">
                            <lb-list :allowrepeat="true" :type="pv.type" v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="(isreviewer || stage === 2) || (!isreviewer && !ispreparer)" />
                          </div>
                        </div>
                      </div>
                      <v-divider></v-divider>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item :key="2" v-if="(((metadata || {}).parameters || []).length > 0) || !needinputparameter">
                  <div class="" v-for="(v,k) in analysis.file" :key="k">
                    <div class="pa-2 hoverable" v-if="filecounter">
                      <div class="subtitle-2 d-flex align-center">
                        <div class="font-weight-bold">
                          {{k+1}}. {{v.name || "No name found!!"}}
                          <v-icon color="success" class="ml-2" v-if="(fileuploaddata[v._id] || {}).processed === 1">mdi-check-circle</v-icon>
                          <v-icon color="info" class="ml-2" v-else>mdi-timer-sand</v-icon>
                        </div>
                        <v-spacer></v-spacer>
                        <div v-if="fileprocessstatus[v._id] === -1" class="ml-2">
                          <span class="error--text">{{fileprocesserrors[v._id] || "Unknown Error"}}</span>
                        </div>
                        <span v-if="fileuploaddata[v._id]" @click="$nova.downloadFile((fileuploaddata[v._id] || {})._id)" class="mx-2 cursor-pointer blue--text text--darken-2 caption">
                          <v-icon class="mr-1 blue--text text--darken-2">mdi-file</v-icon>
                          {{fileuploaddata[v._id].uploadname}}
                        </span>
                        <div v-if="fileprocessstatus[v._id] === 0 && counter" class="ml-2">
                          <v-chip small color="info" :value="true" label>
                            Validating
                          </v-chip>
                        </div>
                        <div v-else-if="fileprocessstatus[v._id] === 1 && counter" class="ml-2">
                          <v-chip small label color="warning" :value="true">
                            Processing
                          </v-chip>
                        </div>
                        <div v-else-if="fileprocessstatus[v._id] === 2 && counter" class="ml-2">
                          <v-chip small label color="info" :value="true">
                            Uploading
                            {{fileuploadpercent[v._id] + "%"}}
                          </v-chip>
                        </div>
                        <div class="ml-2 d-flex align-center" v-else-if="(!isreviewer && !running && stage===0) && !(!isreviewer && !ispreparer)">
                          <lb-file :hidedetails="true" :drag="false" v-model="filesdata[v._id]" accept=".csv,.xlsx,.xls" label="" :displayname="false" @change="processFileChanges(v._id, v.columns || [])"/>
                        </div>
                      </div>
                     
                      <div class="grey--text text--darken-1">Required columns:</div>
                      <div v-if="(filesconfigdata[v._id] || {}).columnmap">
                        <v-chip class="mr-1 mb-1" small v-for="(cv, ck) in (filesconfigdata[v._id] || {}).columnmap" :key="ck">{{cv[1] || "Unknown"}}</v-chip>
                      </div>
                      <div v-else>
                        <v-chip class="mr-1 mb-1" small v-for="(cv, ck) in (v.columns || [])" :key="ck">{{cv.name || ""}}</v-chip>
                      </div>
                    </div>
                    <v-divider></v-divider>
                  </div>
                  <div class="">
                    <div class="pa-2 hoverable">
                      <div class="subtitle-2 d-flex align-center">
                        <div class="font-weight-bold">
                          <div>{{((analysis.file || []).length)+1}}. Vendor Master & Provision</div>
                        </div>
                        <v-spacer></v-spacer>
                      </div>
                      <div>This data will be taken from the Vendor Master</div>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </v-tab-item> 
                <v-tab-item :key="3">
                  <div class="pa-6 text-center title font-weight-bold grey--text" v-if="running">
                    Result will be displayed once the analysis is finished running
                  </div>
                  <div class="pa-6 text-center title font-weight-bold error--text text--lighten-2" v-else-if="(rundetails['TDS_RECO6'] || {}).stage === 2">
                    There was an error while running the analysis.<br/> Please re-run after sometime.
                  </div>
                  <div class="d-flex mt-2" v-else-if="(rundetails['TDS_RECO6'] || {}).stage === 1">
                    <div style="min-width:250px;">
                      <v-btn color="primary" class="mb-4 shadow-off" small @click="downloadResult((rundetails['TDS_RECO6'] || {}).verification)" v-if="isreviewer || ispreparer">
                        <v-icon class="mr-1">mdi-file-excel</v-icon>
                        Download Excel
                      </v-btn>
                      <template v-for="(v,k) in formattedannexurelist">
                        <div v-if="v.type==='heading'" :key="k" class="border-left-grey px-1 mb-1 font-weight-bold grey--text body-2">{{v.name}}</div>
                        <lb-divider v-else-if="v.type==='divider'" class="my-3" :key="k"></lb-divider>
                        <div v-else :key="k" @click="selectedresulttab=v.name;readAnnexure(v.collection, v.name)" :class="`px-1 d-flex align-start cursor-pointer hoverable ${(selectedresulttab===v.name)?'font-weight-bold grey lighten-4':''}`">
                          <v-icon class="mt-1">mdi-chevron-right</v-icon>
                          <span>{{v.name}}</span>
                        </div>
                      </template>
                    </div>
                    <div class="flex-grow-1 px-4" style="min-width: 0">
                      <div class="" v-if="currentannexuredata.length > 0">
                        <div v-if="['Multiple Rates', 'Detailed Workings','Not Considered'].indexOf(selectedresulttab) > -1">
                          <div class="pt-2 pb-2 d-flex align-center">
                            <lb-string v-model="filtertext" :hidedetails="true" label="Search" appendiconinner="mdi-format-list-checks" @click:append="filteritemsdialog = true"/>
                            <lb-dropdown v-if="selectedresulttab === 'Multiple Rates'" class="ml-2" :items="['No Section Selected','All', 'Section Selected']" label="Filter" width="220px" v-model="filteronlyrows" hidedetails/>
                            <v-btn class="ml-2" color="primary" fab small elevation="0" @click="filterAnnexure(selectedresultcollection, selectedresulttab, (selectedresulttab === 'Multiple Rates')?true:false)"><v-icon>mdi-magnify</v-icon></v-btn>
                            <v-spacer></v-spacer>
                          </div>
                          <div class="pt-2 pb-4 d-flex align-center" v-if="!isreviewer || !(!isreviewer && !ispreparer)">
                            <div v-if="selectallrows || selectedrows.length>0" class="d-flex align-center">
                              <lb-dropdown :items="tdssectionlist" label="Section" width="180px" v-model="selectedtdssection" hidedetails/>
                              <v-btn v-if="selectedtdssection || !isreviewer" class="ml-2" elevation="0" color="success" @click="applytdssectiondialog = true"><v-icon class="mr-2">mdi-format-list-checks</v-icon>Apply TDS Section</v-btn>
                            </div>

                            <v-dialog v-model="applytdssectiondialog" persistent width="500" v-if="!isreviewer || !(!isreviewer && !ispreparer)">
                              <v-card :loading="applytdssectionloading" loader-height="2" :disabled="applytdssectionloading">
                                <v-card-text class="primary white--text d-flex align-center py-2 px-4">
                                  <span class="subtitle-1 white--text">Confirm!</span>
                                  <v-spacer></v-spacer>
                                  <v-btn icon @click="applytdssectiondialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
                                </v-card-text>
                                <v-card-text class="pa-0" v-if="!isreviewer || !(!isreviewer && !ispreparer)">
                                  <div class="pa-4">
                                    This will update the TDS Section of <b>{{selectallrows?'all':(selectedrows.length + ' selected')}}</b> items to <b>{{selectedtdssection}}</b>.<br/>
                                    <span v-if="selectedresulttab === 'Multiple Rates' || selectedresulttab === 'Not Considered'">Do you want to continue?</span>
                                    <div v-else-if="selectedresulttab === 'Detailed Workings'">
                                      <div class="mb-4">Please provide a reason below to continue</div>
                                      <lb-textarea v-model="sectionchangereason" label="Reason" hidedetails/>
                                    </div>
                                  </div>
                                  <div class="pa-4 d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="selectedresulttab === 'Multiple Rates'" color="primary" elevation="0" @click="changeTdsSection('multiple', selectedresultcollection, true)">Proceed</v-btn>
                                    <v-btn :disabled="!sectionchangereason && selectedresulttab === 'Detailed Workings'" v-else-if="selectedresulttab === 'Detailed Workings' || selectedresulttab === 'Not Considered'" color="primary" elevation="0" @click="changeTdsSection(selectedresulttab === 'Detailed Workings'?'detailed':'notconsidered', selectedresultcollection)">Yes</v-btn>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-dialog>

                            <v-spacer></v-spacer>
                            <v-chip label color="grey lighten-2" class="pr-0 my-1" v-if="isreviewer">Selected rows
                              <v-chip label  class="ml-2" color="grey lighten-1">
                                <span v-if="selectallrows">All</span>
                                <span v-else>{{selectedrows.length}}</span>
                              </v-chip>
                            </v-chip>
                          </div>
                        </div>
                        <perfect-scrollbar style="max-height: 70vh">
                          <v-simple-table dense :fixed-header="true">
                            <template v-slot:default>
                              <thead>
                                <tr class="grey lighten-3">
                                  <th scope="checkbox" v-if="['Multiple Rates', 'Detailed Workings','Not Considered'].indexOf(selectedresulttab) > -1 && !isreviewer"><v-checkbox v-model="selectallrows"></v-checkbox></th>
                                  <template v-for="(v,k) in currentannexuredata[0]">
                                    <th scope="k" class="" :key="k" v-if="k!=='_id'">{{(k || "").replaceAll("_", " ")}}</th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v,k) in currentannexuredata" :key="k">
                                  <td v-if="['Multiple Rates', 'Detailed Workings','Not Considered'].indexOf(selectedresulttab) > -1 && !isreviewer">
                                    <v-checkbox v-if="!selectallrows" multiple v-model="selectedrows" :value="v._id"></v-checkbox>
                                    <v-icon v-else color="primary" class="mb-1 ml-1">mdi-checkbox-marked</v-icon>
                                  </td>
                                  <template v-for="(vv,kk) in currentannexuredata[0]">
                                    <td :key="kk" v-if="kk!=='_id'">
                                      {{(typeof v[kk] === "number")?$nova.formatNumber(v[kk].toFixed(0)):v[kk]}}
                                    </td>
                                  </template>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </perfect-scrollbar>
                      </div>
                      <div v-else class="text-center grey--text title pa-6">No data available</div>
                    </div>
                  </div>
                </v-tab-item> 
              </v-tabs-items>

            </div>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center mb-4">
            <div class="subtitle-1">
              Analysis for the month of <span class="font-weight-bold">{{new Date(new Date().setMonth((month || 1)-1)).toLocaleString('default', { month: 'long' })}}</span> relating to year <span class="font-weight-bold">{{year}}</span> is not yet started.
            </div>
            <div class="mt-2">
              <v-btn color="primary" small @click="startProject()"><v-icon class="mr-1">mdi-play</v-icon>Start Analysis</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-dialog v-model="filteritemsdialog" max-width="400">
        <v-card>
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Items to filter</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="filteritemsdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-0">
            <div class="pa-4" v-if="annexurehead.length > 0">
              <perfect-scrollbar style="max-height: 70vh">
                <template v-for="(hv, hk) in annexurehead">
                  <div v-if="hv !== '_id'" class="d-flex align-center" :key="hk">
                    <v-checkbox hide-details dense v-model="filteritems" :value="hv" multiple></v-checkbox>
                    <span class=" mt-2">{{hv}}</span>
                  </div>
                </template>
              </perfect-scrollbar>
            </div>
            <div class="pa-4" v-else>
              <v-alert text small type="error" :value="true">
                No columns found!!
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>     

    </div>
  </div>
</template>

<script>
let xlsx = require('xlsx');

export default {
  name: 'tds_monthly_view',
  data: function(){
    return {
      generateddescription: "",
      metadata: {},
      year: "",
      month: "",
      needinputparameter: false,
      sanitizeflag: false,
      sanitizeerror: false,
      analysis: {},
      filesdata: {},
      isreviewer: false,
      isviewon: false,
      ispreparer: false,
      fileuploaddata: {},
      filesconfigdata: {},
      rundetails: [],
      selectedresulttab: "Summary",
      selectedresultcollection: "",
      annexuredata: {},
      annexurechartdata: {},
      currentannexuredata: [],
      chartobject: null,
      enablechart: false,
      filecounter: 1,
      stage: -1,
      filtertext: '',
      filteritems: [],
      filteritemsdialog: false,
      filteronlyrows: "No Section Selected",
      sectionchangereason: "",
      tdssectionlist: ['194J(a)','194J(b)','194A','194I(a)','194I(b)','194C','194Q','194H','195','206','194R','Equalisation Levy','N.A','Duplicate'],
      selectedtdssection: "",
      selectallrows: false,
      selectedrows: [],
      applytdssectiondialog: false,
      applytdssectionloading: false,
      annexurehead: [],

      fileprocessstatus: {},
      fileprocesserrors: {},
      fileuploadpercent: {},
      allfileuploaded: false,
      running: false,
      runerror: false,
      inputparametersdata: {},
      id: "",
      tab: 0,
      loading: false,
      counter: 0,
    }
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    this.year = this.$route.params.year;
    this.month = this.$route.params.month;
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
 

  methods: {
    refreshData() {
      
      if(this.$nova.hasRight("tds_monthly", 'review')) this.isreviewer = true;
      else this.isreviewer = false;
      if(this.$nova.hasRight("tds_monthly", ['add', 'edit'])) this.ispreparer = true;
      else this.ispreparer = false;

      if(this.$nova.hasRight("tds_monthly", ['read'])) this.isviewon = true;
      else this.isviewon = false;

      this.id = this.$route.params.id || "";
      this.annexuredata = {};
      this.getData();
    },
    getData() {
      this.loading = true;
     
      return this.axios.post("/v2/tds/monthly/getdetails/y/"+this.year+"/m/"+this.month).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          this.id = this.metadata._id || "";
          this.stage = this.metadata.stage;
          this.analysis = data.setup || {};
          if((this.analysis.parameter || []).length > 0) this.needinputparameter = true;
          else this.needinputparameter = false;
          this.fileuploaddata = data.uploaddetails || {};
          this.running = false;
          this.runerror = false;
          for (let i = 0; i < (data.rundetails || []).length; i++) {
            const el = (data.rundetails || [])[i];
            this.rundetails[(el.verification_detail || {}).code] = el;            
            if([0,4].indexOf(el.stage) > -1) this.running = true;
            if(el.stage === 2) this.runerror = true;
          }
          this.fileprocessstatus = {};
          this.fileprocesserrors = {};
          let hasfileprocesserror = false;
          let inprocessing = false;
          for (const k in this.fileuploaddata) {
            if (Object.hasOwnProperty.call(this.fileuploaddata, k)) {
              const el = this.fileuploaddata[k];
              if(el.processed !== 1 && el.processed !== -1) {
                inprocessing = true;
                this.fileprocessstatus[k] = 1; 
              }
              else if(el.processed === -1) {
                this.fileprocessstatus[k] = -1;
                this.fileprocesserrors[k] = "Processing Error";
                hasfileprocesserror = true;
              }
            }
          }
          this.counter++;
          this.allfileuploaded = false;
          if(((this.analysis || {}).file || []).length === Object.keys(this.fileuploaddata).length){
            if(!hasfileprocesserror && !inprocessing) this.allfileuploaded = true;
          }
          console.log(this.allfileuploaded);
         
          if(this.needinputparameter){
            this.inputparametersdata = {};
            for (let i = 0; i < (this.analysis.parameter || []).length; i++) {
              const el = (this.analysis.parameter || [])[i];
              this.inputparametersdata[el._id] = el.value;
            }
            for (const k in (this.analysis.inputparameterconfig || {})) {
              if (Object.hasOwnProperty.call((this.analysis.inputparameterconfig || {}), k)) {
                const el = (this.analysis.inputparameterconfig || {})[k];
                this.inputparametersdata[el.parameter] = el.value;
              }
            }
            for (const i of (this.metadata.parameters || [])) {
              this.inputparametersdata[i.parameterid] = i.value;
            }
          }          
          
          this.filesconfigdata = {};
          for (const k in (this.analysis.fileconfig || {})) {
            if (Object.hasOwnProperty.call((this.analysis.fileconfig || {}), k)) {
              const el = (this.analysis.fileconfig || {})[k];
            this.filesconfigdata[k] = el;
            }
          }
          if((data.rundetails || []).length > 0) {
            this.tab = 3;
            this.readAnnexure(this.formattedannexurelist[1].collection, this.formattedannexurelist[1].name, this.selectedresulttab=this.formattedannexurelist[1].name)
          }
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    saveParameters() {
      this.loading = true;
      let ndt = [];
      for (const k in this.inputparametersdata) {
        if (Object.hasOwnProperty.call(this.inputparametersdata, k)) {
          const el = this.inputparametersdata[k];
          ndt.push({parameterid: k, value: el});          
        }
      }
      let successflag = false;
      this.axios.post("/v2/tds/monthly/saveparameters/"+this.id, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Input Parameters Updated");
          successflag = true;
        }else throw new Error ("Error updating input parameters");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        if(successflag) this.refreshData();
      })
    },
    startProject() {
      this.loading = true;
      this.axios.post("/v2/tds/monthly/start/y/"+this.year+"/m/"+this.month).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project started");
          this.refreshData();
        }else throw new Error (dt.data.message || "Error starting project");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    closeProject() {
      this.loading = true;
      this.axios.post("/v2/tds/monthly/close/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Closed");
          this.refreshData();
        }else throw new Error ("Error closing project");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    filterAnnexure: function(c, name, tdssectionfilter, force=false){
      delete this.annexuredata[c];
      if(!this.loading || force){
        this.selectallrows = false;
        this.selectedrows = [];
        this.selectedtdssection = "";
        this.applytdssectiondialog = false;
        this.applytdssectionloading = false;
        this.sectionchangereason = "";
        this.readAnnexure(c, name, {search: this.filtertext, searchin: this.filteritems, tdssectionfilter: tdssectionfilter}); 
      }
    },
    setAnnexureHeaders: function(d, resetfilteritems = false){
      this.annexurehead = [];
      for (const k in d) {
        if (Object.hasOwnProperty.call(d, k)) {
          const el = d[k];
          if(k !== "_id" && typeof el === "string") this.annexurehead.push(k);
        }
      }
      if(resetfilteritems){
        if(this.annexurehead.length > 0) this.filteritems = [this.annexurehead[0]];
        else this.filteritems = [];
      }
    },
    readAnnexure(c, name="", ops={}) {
      this.selectedresultcollection=c;
      let options = {includeid :true, filter: {}};
      if(ops.search && ops.searchin) options.filter = {search: ops.search || '', searchin: ops.searchin};
      if(ops.tdssectionfilter){
        if(this.filteronlyrows === "No Section Selected") options.filter.blank = ['TDS Section'];
        else if(this.filteronlyrows === "Section Selected") options.filter.noblank  = ['TDS Section']
      }
      if(Object.keys(options.filter).length > 0) delete this.annexuredata[c];
      if(this.annexuredata[c]) {
        this.currentannexuredata = this.annexuredata[c];
        this.setAnnexureHeaders(this.currentannexuredata[0] || []);
        if(name === "Summary___") {
          this.enablechart = true;
          this.$nextTick(() => {
            this.setChartData();
          });
        }else this.enablechart = false;
      }
      else{
        this.loading = true;
        Promise.resolve().then(() => {
          return this.axios.post("/v2/tds/monthly/readannexure/"+this.id+"/c/"+c, options);
        }).then(dt => {
          if(dt.data.status === "success"){
            this.annexuredata[c] = dt.data.data;
            this.currentannexuredata = this.annexuredata[c]
            this.setAnnexureHeaders(this.currentannexuredata[0] || []);
            if(name === "Summary___") {
              this.enablechart = true;
              this.$nextTick(() => {
                this.setChartData();
              });
            }else this.enablechart = false;
          }else throw new Error ("Error reading annexure");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    changeTdsSection: function(type, collection, tdssectionfilter=false){
      // console.log(type, collection, this.analysis, this.selectallrows, this.selectedrows, this.selectedtdssection);
      this.applytdssectionloading = true;
      Promise.resolve().then(() => {
        if(type && collection && this.analysis){
          if((this.selectallrows.length > 0 || this.selectedrows)){
            if(this.selectedtdssection){
              if(type === 'detailed' && !this.sectionchangereason) throw new "Reason required!";
              else{
                let filter = {searchin: this.filteritems, search: this.filtertext};
                if(tdssectionfilter){
                  if(this.filteronlyrows === "No Section Selected") filter.blank = ['TDS Section'];
                  else if(this.filteronlyrows === "Section Selected") filter.noblank  = ['TDS Section']
                }
                return this.axios.post('/v2/tds/monthly/updatetdssection/'+this.metadata._id, {collection: collection, type: type, selectallrows: this.selectallrows, selectedrows: this.selectedrows, selectedtdssection: this.selectedtdssection, reason: this.sectionchangereason || '', filter: filter});
              }
            }else throw new "No TDS Section selected";
          }else throw new "No rows selected";
        }else throw new "Incomplete data provided to apply changes"
      }).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "TDS Section updated");
          this.applytdssectiondialog = false;
          
          this.sectionchangereason = "";
          return this.filterAnnexure(collection, tdssectionfilter, false, true);
        }
        else throw new dt.data.message || "Error updating section";
      }).catch(e => {
        console.log(e);
        this.$store.commit("sbError", e.message || e);
      }).finally(() => {
        this.applytdssectionloading = false;
      });
    },
    downloadResult(verification) {
      this.loading = true;
      this.axios.post("/v2/tds/monthly/downloadfile/"+this.id+"/v/"+verification).then(dt => {
        if(dt.data.status === "success"){
          console.log(dt.data.data);
          let data = dt.data.data[0] || {};
          if(data.stage === 0 || data.stage === 4) this.$store.commit("sbSuccess", "File is being generated please try after sometime");
          else if(data.stage === 2) this.$store.commit("sbSuccess", "There was an error while generating file");
          else if(data.upload) this.$nova.downloadFile(data.upload);
          else throw new "no file found to download"
        }else {
          throw new Error (dt.data.message || "Error re-opening project");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    openProject(t="open") {
      this.loading = true;
      this.axios.post("/v2/tds/monthly/open/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          if(t === "back") this.$store.commit("sbSuccess", "Project sent back to preparer");
          else this.$store.commit("sbSuccess", "Project Re-opened");
          this.refreshData();         
        }else {
          if(t === "back") throw new Error ("Error sending back");
          else  throw new Error ("Error re-opening project");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    sendForReview() {
      this.loading = true;
      this.axios.post("/v2/tds/monthly/sendforreview/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project sent for review");
          this.refreshData();
        }else throw new Error ("Error while sending for review");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    processFileChanges(id, columns = []) {
      if(this.filesdata[id] && this.fileprocessstatus[id] !== 0){
        this.fileprocessstatus[id] = 0;
        let thisfileconfigdata = {};
        Promise.resolve().then(() => {
          if(!this.filesdata[id]) throw new Error ("This file is required!");
          else if(columns.length === 0) throw new Error ("0 columns found in master");
          else if(!this.filesdata[id].name) throw new Error ("Upload a valid file");
          else{
            this.fileprocesserrors[id] = "";
            let file = this.filesdata[id];
            if(['xls','xlsx','csv'].includes(file.name.split('.').pop())){
              if(['xls','xlsx'].includes(file.name.split('.').pop()) && file.size > (1024*1024*2)){
                throw new Error ("File with size > 2MB should be in CSV format");
              }else{
               
                if(Object.prototype.hasOwnProperty.call(this.filesconfigdata,id)){
                  thisfileconfigdata = this.filesconfigdata[id] || {};
                }
               return this.checkUploadFileHeaders(this.filesdata[id], columns, this.filesdata[id].name.split('.').pop(), thisfileconfigdata);
              }
            }else throw new Error ("Upload only XLSX, XLS or CSV files");
          }
        }).then((dt = {}) => {
          if(dt.duplicates) throw new Error ("Duplicates found: "+ dt.duplicates.join(","));
          else if((dt.missingcols || []).length > 0) throw new Error ("'" + dt.missingcols.join(", ") + "' column(s) not found");
          else if((dt.data || []).length > 0){
            this.fileprocessstatus[id] = 2;
            this.counter++;
            return this.$nova.uploadFile(this.axios, this.filesdata[id], {
              progressfunction: (e) => {
                let p = e.loaded*100/e.total;
                this.counter++;
                this.fileuploadpercent[id] = p.toFixed(0);
              }
            });
          }else throw new "no data found to process";
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.fileprocessstatus[id] = 1;
            this.counter++;
            let data = {upload: dt.data.data[0]._id, file: id, config: thisfileconfigdata};
            let url = "/v2/tds/monthly/storefile/"+this.id;
            return this.axios.post(url, {data: [data]});
          }else throw new "Unable to upload file";
        }).then(dt => {
          if(dt.data.status === "success"){
            this.refreshData();
            this.filesdata[id] = null;
            this.fileprocesserrors[id] = "";
          }else throw new  dt.data.message || "error uploading file";
        }).catch(err => {
          console.log(err);
          this.fileprocessstatus[id] = -1;
          this.filecounter += 1;
          this.$store.commit("sbError", err.message || err || "Error while processing the file");
          this.filesdata[id] = null;
          this.fileprocesserrors[id] = err.message || err || "Error while processing the file";
        }).finally(() => {
        });
      }
    },
    startAnalysis: function(){
      this.loading = true;
      this.axios.post("/v2/tds/monthly/startrun/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Analysis started");
          this.refreshData();
        }else throw new Error ("Error starting analysis");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })    },
    checkUploadFileHeaders: function(f, cols, fmt, ops={}){
      let t = Number(ops.ignorerows) || 0;
      let l = Number(ops.ignorecols) || 0;
      let c = [];
      for (const i of cols) {
        c.push(i.name);
      }
      if(ops.columnmap instanceof Array){
        c = [];
        ops.columnmap.forEach(x => {c.push(x[1] || x[0])});
      }
      console.log("Check columns",t,l,c,fmt,ops);
      return new Promise((resolve, reject) => {
        let readCSVFile = new FileReader();
        let headerFile = new FileReader();
        let hf = null;
        readCSVFile.onload = function(e) {
          let d = e.target.result.split('\n');
         
          if(d.length >= t+1){
           
            let hd = d.slice(0, t+25);
            hd = hd.join("\n");
            
            
            hf = new File([hd], 'tfile', {type:'text/csv'});
            headerFile.readAsBinaryString(hf);
          }
          else{
            reject(Error("Invalid File"));
          }
        }
        readCSVFile.onerror = function(ex) {reject(ex)};
        headerFile.onload = function(e) {
          let d = e.target.result;
          
          let wb = xlsx.read(d, {type: 'binary', cellHTML: false, raw: true, codepage: 1252}); 
          let rdt = [];
          
          let sh = wb.SheetNames[0];
          
          let dt = xlsx.utils.sheet_to_json(wb.Sheets[sh], {header: 1, raw: false});
          
          if(ops.removeblankrows){
              let ndt = [];

              for (const i of dt) {
                if(i.length !== 0 && i.join("").length !== 0) ndt.push(i);
              }
              dt = ndt;
          }
          dt = dt.splice(Math.max(t-1,0),dt.length);
          
          let prevrowkey = Math.min(t-1, 0);
          let prevrow = dt[prevrowkey] || [];
          let h = dt[prevrowkey+1] || [];
          let nextrow = dt[prevrowkey+2] || [];
          let flag = true;
          for (let i = 0; i < h.length; i++) {
            const el = h[i];
            if(!el){
                if(ops.blankcolumnheaders === "bottom") h[i] = nextrow[i] || "";
                else if(ops.blankcolumnheaders === "top") h[i] = prevrow[i] || "";
                if(ops.blankcolumnheaders === "left") h[i] = h[i-1] || "";
                if(ops.blankcolumnheaders === "right") h[i] = h[i+1] || "";
            }
          }
          
          let missingcols = [];
          h = h.slice(l,h.length);
          h = h.map(x => {return x.toString().trim();})
          
          if(ops.keepduplicatecolumns){
            for (let i = 0; i < h.length; i++) {
              const el = h[i];
              let counter = 1;
              for (let j = i+1; j < h.length; j++) {
                const oel = h[j];
                if(oel === el){
                  if(counter === 1) {
                    h[i] = el+"_"+counter;
                    counter = counter+1;
                  }
                  h[j] = oel+"_"+counter;
                  counter = counter+1;
                }
              }
            }
          }

          for (const k of c) {
            let el = k.toString().trim();
            if(h.indexOf(el) === -1) {
              missingcols.push(k.toString());
              flag = false;
            } 
          }          
          if(flag){
            rdt = dt;
            if(l > 0){
              rdt = rdt.map(el => {
                return el.slice(l,el.length);
              });
            }
          }
         
          resolve({data: rdt, missingcols: missingcols});
        }
        headerFile.onerror = function(ex) {reject(ex)};
        if(fmt == "csv") readCSVFile.readAsText(f.slice(0, 1024*1024*20));
        else headerFile.readAsBinaryString(f);
      });
    },
    uploadFile(file, fid){
      if(this.metadata.parameters){
        if(this.fileprocessstatus[fid] == 0){
         
          this.fileprocessstatus[fid] = 1;
          
          return new Promise((resolve, reject) => {
           
          this.$nova.uploadFile(this.axios, file, {
              progressfunction: (e) => {
                let p = e.loaded*100/e.total;
                this.counter++;
                this.fileuploadpercent[fid] = p.toFixed(0);
              }
            }).then(x => {
              if(x.data.status === "success"){
                this.filesdata[fid] = null;
                this.fileprocessstatus[fid] = 2;
                
                resolve(x);
              }else reject(Error(x.data.message));
            }).catch(e => {
              reject(e);
            });
          });
        }
      }else{
        this.$store.commit("sbError", "Cannot store file before storing the parameters");
      }
    },
  },
  computed: {
    formattedannexurelist: function(){
      let dta = [];
      let catdata = {};
      for (let i = 0; i < ((this.rundetails['TDS_RECO6'] || {}).annexure || []).length; i++) {
        const el = ((this.rundetails['TDS_RECO6'] || {}).annexure || [])[i];
        let cat = el.file || "Consark";
        catdata[cat] = catdata[cat] || [];
        if(el.collection) catdata[cat].push({...el});
      }
      if((catdata["Consark"] || []).length > 0){
        dta.push({type: 'heading', name: "Consark"});
        dta = [...dta, ...catdata["Consark"]];
        dta.push({type: 'divider'});
      }
      for (const k in catdata) {
        if (Object.hasOwnProperty.call(catdata, k)) {
          if(k !== "Consark"){
            const el = catdata[k];
            dta.push({type: 'heading', name: k});
            dta = [...dta, ...el];
            dta.push({type: 'divider'});
          }
        }
      }
      return dta;
    },
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
    "addeditproject.from": function(){
      this.generateDescription();
    },
    "addeditproject.to": function(){
      this.generateDescription();
    },
    "$route": function(d){
      if(d.hash === "#add") {
        this.addprojectdialog = true;
        this.addeditproject = {};
        this.addeditproject.reminderdays = this.addeditproject.reminderdays ?? 7;
      }
    },
    "addprojectdialog": function(d){
      if(!d) this.$nova.gotoLink({hash: ''});
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-tabs .v-tab {
  height: 40px !important;
}
</style>